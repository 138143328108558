<template>
    <div class="app-wrapper">
        <div class="top-nav">
            <div class="logo">
                <img src="@/assets/images/logo.png" alt="">
            </div>
            <div class="ml20 nav">
                <!--                <TopBar :matched="matched"></TopBar>-->
                <!--                <userInfo></userInfo>-->
                <el-button type="text" @click="dialogVisible=true">修改密码</el-button>
                <span class="mr10 ml40">
                    当前登录：{{userInfo.userName}}
                </span>

                <el-button type="text" @click="clearData">退出</el-button>

            </div>
        </div>
        <!--        <toDoDialog :list="backLoglist" @handleClick="handleClick"-->
        <!--        @openDialog="openDialog"></toDoDialog>-->
        <!--修改密码弹窗-->
        <EDialog :dialogVisible="dialogVisible" title="修改密码"
                 @handleClose="cancelDialog" width="30%" @handleClick="editPassword"
        >
            <EForm :formColumns="formColumns" :rowSize="1" :optionsBtn="false" :actionBtn="true" :formData="form"
                   ref="form" :searchFlag="false" :formRules="formRules">
            </EForm>
        </EDialog>

        <router-view/>
    </div>
</template>

<script>
  import SideBar from '@/components/SideBar'
  import userInfo from '@/components/userInfo'
  import TopBar from '@/components/TopBar'
  import toDoDialog from '@/components/toDoDialog'
  import EDialog from '@/components/EDialog'
  import EForm from '@/components/EForm'
  import Http from '@/service/http'
  import vxRule from "@/assets/js/formValidate";
  import {mapGetters, mapActions} from 'vuex'

  export default {
    data() {
      return {
        dialogVisible: false,
        form: {
          oldPassword: '',
          newPassword: '',
          newPassword1: '',

        },
        formColumns: [
          {
            title: '原始密码',
            type: 'password',
            property: 'oldPassword',
            show: true,
          },
          {
            title: '新密码',
            type: 'password',
            property: 'newPassword',
            show: true,
          },
          {
            title: '确认密码',
            type: 'password',
            property: 'newPassword1',
            show: true,
          },
          {
            type: 'actionBtn',
            show: false
          }
        ],
        formRules: {
          oldPassword: vxRule(true, '', "blur", "原始密码不能为空"),
          newPassword: [vxRule(true, '', "blur", "新密码不能为空"),
            vxRule(true, val => {
              if (val.length < 8 || val.length > 24) {
                return {errMsg: "密码必须为8-24个字符"}
              } else {
                  const regex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[\W_]).{8,24}$/;
                  if(!regex.test(val)){
                    return {errMsg: "密码必须为8-24位字母,数字和符号"}
                  }
                  
              }
              
            })
          ],
          newPassword1: [vxRule(true, '', "blur", "确认密码不能为空"),
            vxRule(true, val => {
              return {result: val === this.form.newPassword, errMsg: "确认密码与新密码不一致"}
            })],
        }
      }
    },
    components: {
      SideBar,
      userInfo,
      TopBar,
      toDoDialog,
      EDialog,
      EForm
    },
    computed: {
      ...mapGetters(['userInfo']),
      matched() {
        return this.$route.matched
      }
    },
    methods: {
      ...mapActions(['setTips', 'setMenu', 'setUserInfo', 'setButton', 'setDisabled']),
      handleClick({name, type}) {
        this.$router.push({name, query: {type}})
      },
      openDialog() {
        this.getData()
      },
      clearData() {
        this.$router.replace({path: '/login'})
        this.setUserInfo({})
        this.setMenu([])
        this.setButton([])
        this.setDisabled(false)
      },
      async editPassword() {
        let res = await Http.updatePassword(this.form)
        if (res.code == 200) {
          this.$message.success('修改成功，请重新登录')
          this.clearData()
          this.cancelDialog()
        }
      },
      cancelDialog() {
        this.dialogVisible = false
        this.form = {
          oldPassword: '',
          newPassword: '',
          newPassword1: '',

        }
      },

    },
    created() {

    }
  }
</script>

<style lang="scss" scoped>


    .top-nav {
        display: flex;
        background-color: #fff;
        padding-right: 20px;
        align-items: center;

        .logo {
            display: table-cell;
            width: 256px;
            height: 60px;
            text-align: center;
            line-height: 60px;
            vertical-align: middle;
            background-color: #fff;
            color: #fff;

            img {
                vertical-align: middle;
                height: 90%;
            }
        }

        .nav {
            /*width:calc(100vw - 256px);*/
            /*display: flex;*/
            /*justify-content: space-between;*/
            /*align-items: center;*/
            flex: 1;
            text-align: right;
            font-size: 14px;
        }
    }
</style>
